import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { Container, Typography, Box, Button, CardMedia } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { mockServices } from '../mockServices';

const ServiceDetail = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const numericId = parseInt(id, 10);
  const service = mockServices.find((service) => service.id === numericId);

  if (!service) return <Typography variant="h6" align="center">Service not found</Typography>;

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Box sx={{ textAlign: 'center', mb: 2 }}>
        <Typography variant="h3" component="h1">
          {t(service.title)}
        </Typography>
      </Box>

      <Typography variant="body1">
        {t(service.description)}
      </Typography>

      <Link to="/services" style={{ textDecoration: 'none' }}>
        <Button variant="outlined" color="success" sx={{ mt: 4, borderRadius: 2, borderColor: 'success.main', color: 'success.main' }}>
        {t('back_to_list')}
        </Button>
      </Link>
    </Container>
  );
};

export default ServiceDetail;
