import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Home from './pages/Home';
import Services from './pages/Services'; 
import ServiceDetail from './pages/ServiceDetail';
import ListingDetail from './pages/ListingDetail';
import Header from './components/Header';
import Footer from './components/Footer';

const App = () => (
  <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
    <Helmet>
      <title>Elite Estates Online</title>
      <meta name="description" content="Discover your perfect home with Elite Estates Online. Explore luxurious properties and find your dream apartment today." />
      <meta name="robots" content="index, follow" />
    </Helmet>
    <Header />
    <main style={{ flex: '1' }}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/service/:id" element={<ServiceDetail />} />
        <Route path="/listing/:id" element={<ListingDetail />} />
      </Routes>
    </main>
    <Footer />
  </div>
);

export default App;