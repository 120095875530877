import React, { useState, useEffect } from 'react';
import {
  Container,
  Box,
  Typography,
  TextField,
  InputAdornment,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Button,
  Pagination,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import HouseIcon from '@mui/icons-material/House';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { mockListings } from '../mockData';

const Home = () => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const [listings, setListings] = useState(mockListings);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const handleSearchChange = (event) => setSearchTerm(event.target.value);

  const handleChange = (event, value) => setPage(value);

  useEffect(() => {
    const fetchListings = () => {
      const searchRegex = new RegExp(searchTerm, 'i');

      const filteredListings = mockListings.filter((listing) => {
        const translatedTitle = t(listing.title).toLowerCase();
        const translatedLocation = t(listing.location).toLowerCase();
        const translatedDescription = t(listing.description).toLowerCase();

        return (
          searchRegex.test(translatedTitle) ||
          searchRegex.test(translatedLocation) ||
          searchRegex.test(translatedDescription)
        );
      });

      const paginatedListings = filteredListings.slice((page - 1) * 10, page * 10);
      setListings(paginatedListings);
      setTotalPages(Math.ceil(filteredListings.length / 10));
    };

    fetchListings();
  }, [searchTerm, page, t]);

  return (
    <>
      <Helmet>
        <title>{t('Elite Estates Online | Luxury Real Estate')}</title>
        <meta
          name="description"
          content={t('Discover luxury real estate with Elite Estates Online. Find your dream home today.')}
        />
        <meta
          name="keywords"
          content="Elite Estates Online, luxury real estate, property listings, buy home, rent apartment, exclusive properties"
        />
      </Helmet>

      <Box
        className="full-width-section"
        component="section"
        sx={{
          width: '100vw',
          maxWidth: '100vw',
          textAlign: 'center',
          paddingBottom: '50px',
          backgroundImage: 'url(/images/hero-bg.jpg)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          padding: '100px 20px',
          color: '#fff',
          textShadow: '2px 2px 4px rgba(0,0,0,0.8)',
          overflow: 'hidden',
          position: 'relative',
          margin: 0,
          boxSizing: 'border-box',
        }}
      >
        <Typography variant="h3" component="h1" sx={{ mb: 2 }}>
          {t('welcome')}
        </Typography>
        <Typography variant="h6" component="p" sx={{ mb: 3 }}>
          {t('site_description')}
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          placeholder={t('search_placeholder')}
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            sx: {
              borderRadius: '25px',
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
            },
          }}
          sx={{
            maxWidth: 600,
            mx: 'auto',
            borderRadius: '25px',
          }}
        />
      </Box>

      <Container maxWidth="lg" sx={{ px: 2, mt: 2 }}>
        <Typography
          variant="h4"
          component="h2"
          sx={{
            color: '#333',
            fontWeight: 'bold',
            lineHeight: 1.4,
            textAlign: 'center',
            margin: '0 auto',
            py: 4,
          }}
        >
          {t('our_listings')}
        </Typography>
        <Grid container spacing={4} component="section">
          {listings.map((listing) => (
            <Grid item key={listing.id} xs={12} sm={6} md={4} component="article">
              <Card
                sx={{
                  border: '1px solid #ddd',
                  borderRadius: 3,
                  boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                    boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
                  },
                }}
              >
                <CardMedia
                  component="img"
                  image={listing.imagegen}
                  alt={t(listing.title) || 'Listing Image'}
                  sx={{ height: 250, objectFit: 'cover', borderRadius: '3px 3px 0 0' }}
                />
                <CardContent
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    p: 3,
                    bgcolor: '#f8f9fa',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <Typography variant="h6" component="h3" sx={{ fontWeight: 'bold', mb: 1, color: '#333' }}>
                    {t(listing.title) || t('no_title')}
                  </Typography>
                  <Typography variant="body2" component="p" sx={{ mb: 0.5, display: 'flex', alignItems: 'center' }}>
                    <LocationOnIcon sx={{ fontSize: 20, mr: 0.5, color: '#4caf50' }} />
                    <strong>{t('location')}: </strong> {t(listing.location) || t('not_specified')}
                  </Typography>
                  <Typography variant="body2" component="p" sx={{ mb: 0.5, display: 'flex', alignItems: 'center' }}>
                    <AttachMoneyIcon sx={{ fontSize: 20, mr: 0.5, color: '#ff5722' }} />
                    <strong>{t('price')}: </strong> {t(listing.price) || t('not_specified')} EUR
                  </Typography>
                  <Typography variant="body2" component="p" sx={{ mb: 1, display: 'flex', alignItems: 'center' }}>
                    <HouseIcon sx={{ fontSize: 20, mr: 0.5, color: '#3f51b5' }} />
                    <strong>{t('property_type')}: </strong> {t(listing.type) || t('not_specified')}
                  </Typography>
                  <Box sx={{ mt: 2 }}>
                    <Link to={`/listing/${listing.id}`} style={{ textDecoration: 'none' }}>
                      <Button
                        variant="contained"
                        fullWidth
                        endIcon={<ArrowForwardIcon />}
                        sx={{
                          mt: 'auto',
                          backgroundColor: '#01231D',
                          '&:hover': {
                            backgroundColor: '#014235',
                          },
                        }}
                      >
                        {t('see_more')}
                      </Button>
                    </Link>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        {totalPages > 1 && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handleChange}
              color="primary"
              sx={{
                '& .MuiPaginationItem-root': {
                  borderRadius: '50%',
                  color: '#004d40',
                  border: '1px solid #004d40',
                  margin: '0 4px',
                },
                '& .MuiPaginationItem-previousNext': {
                  color: '#004d40',
                },
                '& .MuiPaginationItem-page.Mui-selected': {
                  backgroundColor: '#004d40',
                  color: '#ffffff',
                  fontWeight: 'bold',
                },
                '& .MuiPaginationItem-page.Mui-selected:hover': {
                  backgroundColor: '#004d40',
                },
                '& .MuiPaginationItem-root:hover': {
                  backgroundColor: 'rgba(0, 77, 64, 0.04)',
                },
                '& .MuiPaginationItem-icon': {
                  color: '#ffffff',
                },
              }}
            />
          </Box>
        )}
      </Container>
    </>
  );
};

export default Home;
