export const mockListings = [
  {
    id: 1,
    imagegen: '/images/apartament_1/img-4.png',
    title: 'listing_title_1',
    location: 'location_1',
    price: 'price_1',
    type: 'property_type_1',
    area: 'area_1',
    constructionType: 'construction_type_1',
    completionLevel: 'completion_level_1',
    floorNumber: 'floor_number_1',
    features: 'features_1',
    maintenance_fee: '3',
    description: 'description_1',
    images: [
      '/images/apartament_1/img-1.png',
      '/images/apartament_1/img-2.png',
      '/images/apartament_1/img-3.png',
      '/images/apartament_1/img-4.png',
      '/images/apartament_1/img-5.png',
      '/images/apartament_1/img-6.png',
      '/images/apartament_1/img-7.png',
      '/images/apartament_1/img-8.png',
    ],
  },
  {
    id: 2,
    imagegen: '/images/apartament_2/img-1.png',
    title: 'listing_title_2',
    location: 'location_2',
    price: 'price_2',
    type: 'property_type_2',
    area: 'area_2',
    constructionType: 'construction_type_2',
    completionLevel: 'completion_level_2',
    floorNumber: 'floor_number_2',
    features: 'features_2',
    maintenance_fee: '3',
    description: 'description_2',
    images: [
      '/images/apartament_2/img-1.png',
      '/images/apartament_2/img-2.png',
      '/images/apartament_2/img-3.png',
      '/images/apartament_2/img-4.png',
      '/images/apartament_2/img-5.png',
      '/images/apartament_2/img-6.png',
    ],
  },
  {
    id: 3,
    imagegen: '/images/apartament_3/img-4.png',
    title: 'listing_title_3',
    location: 'location_3',
    price: 'price_3',
    type: 'property_type_3',
    area: 'area_3',
    constructionType: 'construction_type_3',
    completionLevel: 'completion_level_3',
    floorNumber: 'floor_number_3',
    features: 'features_3',
    maintenance_fee: '7',
    description: 'description_3',
    images: [
      '/images/apartament_3/img-1.png',
      '/images/apartament_3/img-2.png',
      '/images/apartament_3/img-3.png',
      '/images/apartament_3/img-4.png',
      '/images/apartament_3/img-5.png',
      '/images/apartament_3/img-6.png',
      '/images/apartament_3/img-7.png',
      '/images/apartament_3/img-8.png',
    ],
  },
  {
    id: 4,
    imagegen: '/images/apartament_4/img-1.png',
    title: 'listing_title_4',
    location: 'location_4',
    price: 'price_4',
    type: 'property_type_4',
    area: 'area_4',
    constructionType: 'construction_type_4',
    completionLevel: 'completion_level_4',
    floorNumber: 'floor_number_4',
    features: 'features_4',
    maintenance_fee: '10',
    description: 'description_4',
    images: [
      '/images/apartament_4/img-1.png',
      '/images/apartament_4/img-2.png',
      '/images/apartament_4/img-3.png',
      '/images/apartament_4/img-4.png',
      '/images/apartament_4/img-5.png',
      '/images/apartament_4/img-6.png',
      '/images/apartament_4/img-7.png',
      '/images/apartament_4/img-8.png',
      '/images/apartament_4/img-9.png',
    ],
  },
  {
    id: 5,
    imagegen: '/images/apartament_5/img-7.png',
    title: 'listing_title_5',
    location: 'location_5',
    price: 'price_5',
    type: 'property_type_5',
    area: 'area_5',
    constructionType: 'construction_type_5',
    completionLevel: 'completion_level_5',
    floorNumber: 'floor_number_5',
    features: 'features_5',
    maintenance_fee: '3',
    description: 'description_5',
    images: [
      '/images/apartament_5/img-1.png',
      '/images/apartament_5/img-2.png',
      '/images/apartament_5/img-3.png',
      '/images/apartament_5/img-4.png',
      '/images/apartament_5/img-5.png',
      '/images/apartament_5/img-6.png',
      '/images/apartament_5/img-7.png',
      '/images/apartament_5/img-8.png',
      '/images/apartament_5/img-9.png',
      '/images/apartament_5/img-10.png',
      '/images/apartament_5/img-11.png',
    ],
  },
  {
    id: 6,
    imagegen: '/images/apartament_6/img-7.png',
    title: 'listing_title_6',
    location: 'location_6',
    price: 'price_6',
    type: 'property_type_6',
    area: 'area_6',
    constructionType: 'construction_type_6',
    completionLevel: 'completion_level_6',
    floorNumber: 'floor_number_6',
    features: 'features_6',
    maintenance_fee: '10',
    description: 'description_6',
    images: [
      '/images/apartament_6/img-1.png',
      '/images/apartament_6/img-2.png',
      '/images/apartament_6/img-3.png',
      '/images/apartament_6/img-4.png',
      '/images/apartament_6/img-5.png',
      '/images/apartament_6/img-6.png',
      '/images/apartament_6/img-7.png',
      '/images/apartament_6/img-8.png',
      '/images/apartament_6/img-9.png',
      '/images/apartament_6/img-10.png',
    ],
  },
];
