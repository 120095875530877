import React, { useState, useEffect } from 'react';
import {
  Container,
  Box,
  Typography,
  TextField,
  InputAdornment,
  Grid,
  Card,
  CardContent,
  Button,
  Pagination,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { CardMedia } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { mockServices } from '../mockServices';

const Services = () => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const [services, setServices] = useState(mockServices);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const handleSearchChange = (event) => setSearchTerm(event.target.value);

  const handleChange = (event, value) => setPage(value);

  useEffect(() => {
    const fetchServices = () => {
      const searchRegex = new RegExp(searchTerm, 'i');

      const filteredServices = mockServices.filter((service) => {
        const translatedTitle = t(service.title).toLowerCase();
        const translatedDescription = t(service.description).toLowerCase();

        return (
          searchRegex.test(translatedTitle) ||
          searchRegex.test(translatedDescription)
        );
      });

      const paginatedServices = filteredServices.slice((page - 1) * 10, page * 10);
      setServices(paginatedServices);
      setTotalPages(Math.ceil(filteredServices.length / 10));
    };

    fetchServices();
  }, [searchTerm, page, t]);

  return (
    <>
      <Helmet>
        <title>{t('Our Services | Professional Solutions')}</title>
        <meta
          name="description"
          content={t('Explore our range of professional services tailored to meet your needs.')}
        />
        <meta
          name="keywords"
          content="services, professional solutions, expert services, business solutions"
        />
      </Helmet>

      <Container maxWidth="lg" sx={{ px: 2, mt: 2 }}>
        <Typography
          variant="h4"
          component="h2"
          sx={{
            color: '#333',
            fontWeight: 'bold',
            lineHeight: 1.4,
            textAlign: 'center',
            margin: '0 auto',
            py: 4,
          }}
        >
          {t('our_service_list')}
        </Typography>
        <Grid container spacing={4} component="section">
          {services.map((service) => (
            <Grid item key={service.id} xs={12} sm={6} md={4} component="article">
              <Card
                sx={{
                  border: '1px solid #ddd',
                  borderRadius: 3,
                  boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                    boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
                  },
                }}
              >
                <CardMedia
                  component="img"
                  image={service.imagegen}
                  alt={t(service.title) || 'Service Image'}
                  sx={{ height: 250, objectFit: 'cover', borderRadius: '3px 3px 0 0' }}
                />
                <CardContent
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    p: 3,
                    bgcolor: '#f8f9fa',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <Typography variant="h6" component="h3" sx={{ fontWeight: 'bold', mb: 1, color: '#333' }}>
                    {t(service.title) || t('no_title')}
                  </Typography>
                  <Typography variant="descriptionbody2" component="p" sx={{ mb: 1 }}>
                    {t(service.descriptionshort) || t('no_descriptionshort')}
                  </Typography>
                  <Box sx={{ mt: 2 }}>
                    <Link to={`/service/${service.id}`} style={{ textDecoration: 'none' }}>
                      <Button
                        variant="contained"
                        fullWidth
                        sx={{
                          mt: 'auto',
                          backgroundColor: '#01231D',
                          '&:hover': {
                            backgroundColor: '#014235',
                          },
                        }}
                      >
                        {t('see_more')}
                      </Button>
                    </Link>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        {totalPages > 1 && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handleChange}
              color="primary"
              sx={{
                '& .MuiPaginationItem-root': {
                  borderRadius: '50%',
                  color: '#004d40',
                  border: '1px solid #004d40',
                  margin: '0 4px',
                },
                '& .MuiPaginationItem-previousNext': {
                  color: '#004d40',
                },
                '& .MuiPaginationItem-page.Mui-selected': {
                  backgroundColor: '#004d40',
                  color: '#ffffff',
                  fontWeight: 'bold',
                },
                '& .MuiPaginationItem-page.Mui-selected:hover': {
                  backgroundColor: '#004d40',
                },
                '& .MuiPaginationItem-root:hover': {
                  backgroundColor: 'rgba(0, 77, 64, 0.04)',
                },
                '& .MuiPaginationItem-icon': {
                  color: '#ffffff',
                },
              }}
            />
          </Box>
        )}
      </Container>
    </>
  );
};

export default Services;
