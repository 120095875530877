import React from 'react';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import LanguageSwitcher from './LanguageSwitcher';

const Header = () => (
  <AppBar position="static" sx={{ backgroundColor: '#01231D' }}>
    <Toolbar>
      <Typography variant="h6" sx={{ flexGrow: 1 }}>
        Elite Estates Online
      </Typography>
        <Link to="/" style={{ textDecoration: 'none' }}>
          <Button color="inherit" sx={{ 
            marginRight: 2, 
            color: '#fff',
            '&:hover': {
                backgroundColor: '#004d40',
             },
            }}>
            Начало
          </Button>
        </Link>
        <Link to="/services" style={{ textDecoration: 'none' }}>
          <Button color="inherit" sx={{
             marginRight: 2, 
             color: '#fff',
             '&:hover': {
                backgroundColor: '#004d40',
              },
             }}>
            Услуги
          </Button>
        </Link>
      <div style={{ marginRight: '10px' }}>
        <LanguageSwitcher />
      </div>
    </Toolbar>
  </AppBar>
);

export default Header;