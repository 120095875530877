import React from 'react';
import { Menu, MenuItem, IconButton } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (language) => {
    i18n.changeLanguage(language);
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton color="inherit" onClick={handleClick}>
        <LanguageIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        sx ={{
          style: {
            left: 'auto',
            right: 0,
          },
        }}
      >
        <MenuItem onClick={() => handleClose('bg')}>Bulgarian</MenuItem>
        <MenuItem onClick={() => handleClose('en')}>English</MenuItem>
        <MenuItem onClick={() => handleClose('ru')}>Русский</MenuItem>
        <MenuItem onClick={() => handleClose('de')}>Deutsch</MenuItem>
        <MenuItem onClick={() => handleClose('pl')}>Polski</MenuItem>
      </Menu>
    </div>
  );
};

export default LanguageSwitcher;