import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Card, CardContent, CardMedia, Container, Typography, Button, Box, Grid, Modal, IconButton } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useTranslation } from 'react-i18next';
import { mockListings } from '../mockData';

const ListingDetail = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const numericId = parseInt(id, 10);

  const listing = mockListings.find(listing => listing.id === numericId);

  const [open, setOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  if (!listing) return <Typography variant="h6" align="center">{t('listing_not_found')}</Typography>;

  const hasImages = listing.images && listing.images.length > 0;

  const handleOpen = (index) => {
    setSelectedImageIndex(index);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleNext = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex + 1) % listing.images.length);
  };

  const handlePrev = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex - 1 + listing.images.length) % listing.images.length);
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4, px: 3, py: 2 }}>
      <Card elevation={4} sx={{ borderRadius: 4, overflow: 'hidden' }}>
        <CardContent sx={{ px: 3, py: 4 }}>
          <Typography variant="h2" component="div" gutterBottom sx={{ fontWeight: 'bold', fontSize: '2.5rem', mb: 3 }}>
            {t(listing.title)}
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', mb: 4, borderBottom: '1px solid', borderColor: 'divider', pb: 2 }}>
            <Typography variant="h6" color="text.secondary" sx={{ mb: 1 }}>
              {t('price')}: <span style={{ fontWeight: 'bold' }}>{t(listing.price)} EUR</span>
            </Typography>
            <Typography variant="h6" color="text.secondary" sx={{ mb: 1 }}>
              {t('location')}: <span style={{ fontWeight: 'bold' }}>{t(listing.location) || t('not_specified')}</span>
            </Typography>
            <Typography variant="h6" color="text.secondary" sx={{ mb: 1 }}>
              {t('property_type')}: <span style={{ fontWeight: 'bold' }}>{t(listing.type) || t('not_specified')}</span>
            </Typography>
            <Typography variant="h6" color="text.secondary" sx={{ mb: 1 }}>
              {t('area')}: <span style={{ fontWeight: 'bold' }}>{t(listing.area) || t('not_specified')} m²</span>
            </Typography>
            <Typography variant="h6" color="text.secondary" sx={{ mb: 1 }}>
              {t('construction_type')}: <span style={{ fontWeight: 'bold' }}>{t(listing.constructionType) || t('not_specified')}</span>
            </Typography>
            <Typography variant="h6" color="text.secondary" sx={{ mb: 1 }}>
              {t('completion_level')}: <span style={{ fontWeight: 'bold' }}>{t(listing.completionLevel) || t('not_specified')}</span>
            </Typography>
            <Typography variant="h6" color="text.secondary" sx={{ mb: 1 }}>
              {t('floor_number')}: <span style={{ fontWeight: 'bold' }}>{t(listing.floorNumber) || t('not_specified')}</span>
            </Typography>
            <Typography variant="h6" color="text.secondary" sx={{ mb: 1 }}>
              {t('features')}: <span style={{ fontWeight: 'bold' }}>{t(listing.features) || t('not_specified')}</span>
            </Typography>
            <Typography variant="h6" color="text.secondary" sx={{ mb: 2 }}>
              {t('maintenance_fee')}: <span style={{ fontWeight: 'bold' }}>{t(listing.maintenance_fee) || t('not_specified')} EUR/m²</span>
            </Typography>
          </Box>

          {}
          <Box sx={{ mb: 4, pb: 2, borderBottom: '1px solid', borderColor: 'divider' }}>
            <Typography variant="h5" component="div" gutterBottom sx={{ mb: 2, fontWeight: 'bold' }}>
              {t('contact_information')}
            </Typography>
            <Typography variant="h6" color="text.secondary" sx={{ mb: 1 }}>
              <strong>{t('contact_phone')} </strong>
              <a href="tel:+35987855033" style={{ textDecoration: 'none', color: '#01231D' }}>
                +359 87 85 5033
              </a>
            </Typography>
            <Typography variant="h6" color="text.secondary" sx={{ mb: 1 }}>
              <strong>{t('contact_email')} </strong>   
              <a href="mailto:ivanstoikov007@gmail.com" style={{ textDecoration: 'none', color: '#01231D' }}>
                ivanstoikov007@gmail.com
              </a>
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', mb: 4, borderBottom: '1px solid', borderColor: 'divider', pb: 2 }}>
            <Typography variant="h5" component="div" gutterBottom sx={{ mb: 2, fontWeight: 'bold' }}>
              {t('additional_information')}
            </Typography>
            <Typography variant="body1" paragraph sx={{ mb: 3 }}>
              {t(listing.description) || t('no_description')}
            </Typography>
          </Box>

          {hasImages && (
            <Box sx={{ mt: 4 }}>
              <Typography variant="h5" component="div" gutterBottom sx={{ mb: 2, fontWeight: 'bold' }}>
                {t('gallery')}
              </Typography>
              <Grid container spacing={3}>
                {listing.images.map((image, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card elevation={2} sx={{ borderRadius: 2, cursor: 'pointer', overflow: 'hidden' }}>
                      <CardMedia
                        component="div"
                        sx={{ position: 'relative', height: 200 }}
                        onClick={() => handleOpen(index)}
                      >
                        <img
                          src={image}
                          alt=""
                          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                        />
                      </CardMedia>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}

          <Link to="/" style={{ textDecoration: 'none' }}>
            <Button variant="outlined" color="success" sx={{ mt: 4, borderRadius: 2, borderColor: 'success.main', color: 'success.main' }}>
              {t('back_to_list')}
            </Button>
          </Link>
        </CardContent>
      </Card>

      <Modal
        open={open}
        onClose={handleClose}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box
          sx={{
            position: 'relative',
            width: '80%',
            height: '80%',
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            p: 2
          }}
        >
          <IconButton
            onClick={handlePrev}
            sx={{
              position: 'absolute',
              top: '50%',
              left: 0,
              zIndex: 2,
              bgcolor: 'rgba(0,0,0,0.6)',
              color: 'white',
              '&:hover': {
                bgcolor: 'rgba(0,0,0,0.8)',
              }
            }}
          >
            <ChevronLeftIcon />
          </IconButton>
          <img
            src={listing.images[selectedImageIndex]}
            alt=""
            style={{ width: '100%', height: '100%', objectFit: 'contain', borderRadius: '4px' }}
          />
          <IconButton
            onClick={handleNext}
            sx={{
              position: 'absolute',
              top: '50%',
              right: 0,
              zIndex: 2,
              bgcolor: 'rgba(0,0,0,0.6)',
              color: 'white',
              '&:hover': {
                bgcolor: 'rgba(0,0,0,0.8)',
              }
            }}
          >
            <ChevronRightIcon />
          </IconButton>
        </Box>
      </Modal>
    </Container>
  );
};

export default ListingDetail;