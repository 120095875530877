import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en/translation.json';
import translationBG from './locales/bg/translation.json';
import translationRU from './locales/ru/translation.json';
import translationDE from './locales/de/translation.json';
import translationPL from './locales/pl/translation.json';

i18n
  .use(initReactI18next) 
  .init({
    resources: {
      en: {
        translation: translationEN,
      },
      bg: {
        translation: translationBG,
      },
      ru: {
        translation: translationRU,
      },
      de: {
        translation: translationDE,
      },
      pl: {
        translation: translationPL,
      }
    },
    lng: 'bg',
    fallbackLng: 'bg',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
