import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: '#f8f9fa',
        padding: '10px 0',
        position: 'relative',
        bottom: 0,
        width: '100%',
        marginTop: '30px',
      }}
    >
      <Container maxWidth="lg" sx={{ textAlign: 'center' }}>
        <Typography variant="body2" color="textSecondary">
          © {new Date().getFullYear()} Elite Estates Online. {t('footer')}
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
